/* eslint-disable import/no-cycle */
import { formatMessage } from './helpers'
import { ConfigurationType } from './types'
import PhoneValidator, { PhoneFormatter } from './validators/phones'

const configuration: ConfigurationType[] = [
  {
    header: 'Etablissement',
    required: true,
    cell: {
      required: true,
      type: 'select',
    },
  },
  {
    header: 'Equipe',
    required: true,
    cell: {
      required: true,
      type: 'select',
    },
  },
  {
    header: 'matricule',
    required: true,
  },
  {
    header: 'Nom de naissance', // birth_name
    required: true,
  },
  {
    header: "Nom d'usage",
  },
  {
    header: 'Prénom',
    required: true,
    cell: {
      required: true,
    },
  },
  {
    header: 'Situation familiale', // family_situation
  },
  {
    header: 'email',
    required: true,
    cell: {
      type: 'email',
    },
  },
  {
    header: 'Téléphone mobile',
    required: true,
    cell: {
      validate: PhoneValidator,
      afterFormat: PhoneFormatter,
    },
  },
  {
    header: 'Téléphone fixe',
    required: true,
    cell: {
      validate: PhoneValidator,
      afterFormat: PhoneFormatter,
    },
  },
  {
    header: 'Sexe',
    required: true,
    cell: {
      trim: true,
      type: 'select',
      options: [
        'h',
        'male',
        'homme',
        'masculin',
        'f',
        'female',
        'femme',
        'féminin',
        'feminin',
      ],
    },
  },
  {
    header: 'N° de sécurité sociale',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Nationalité',
    required: true,
  },
  {
    header: 'Date de naissance',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Commune de naissance',
    required: true,
  },
  {
    header: 'Département de Naissance',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Pays de naissance', // birth_country
    required: true,
  },
  {
    header: 'Adresse',
    required: true,
  },
  {
    header: 'Code postal',
    required: true,
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Ville',
    required: true,
  },
  {
    header: 'Pays de résidence', // country_of_residence
    required: true,
  },
  {
    header: 'Type de contrat',
    required: true,
    cell: {
      trim: true,
      strict: true,
      type: 'select',
      options: [
        'CDI',
        'CDD',
        'Stagiaire',
        'Saisonnier',
        'Apprentissage',
        'Intérimaire',
        'Extra',
      ],
    },
  },
  {
    header: 'Motif de recours au CDD',
    required: false,
    cell: {
      type: 'select',
      required: false,
      strict: true,
      options: [
        `Accroissement temporaire d’activité`,
        `Remplacement d’un salarié absent`,
      ],
    },
  },
  {
    header: "Nom de l'agence d'interim",
    required: false,
  },
  {
    header: "Adresse de l'agence d'interim",
    required: false,
  },
  {
    header: "Code postal de l'agence d'interim",
    required: false,
  },
  {
    header: "Ville de l'agence d'interim",
    required: false,
  },
  {
    header: 'Tuteur (adresse email)',
    required: false,
  },
  {
    header: 'Responsable hiérarchique (adresse email)',
    required: false,
  },
  {
    header: 'Temps contrat hebdo',
    cell: {
      type: 'number',
    },
  },
  {
    header: 'Nombre de jours travaillés par semaine',
    required: true,
    cell: {
      type: 'number',
      defaultValue: 5,
      validate: (cell: string) => {
        const workDays = Number(cell)

        if (!workDays || workDays < 8) {
          return null
        }

        return formatMessage('The working days should be between 1 to 7.')
      },
    },
  },
  {
    header: "Date d'embauche",
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Date de sortie',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Motif de fin de contrat',
    required: false,
    cell: {
      trim: true,
      strict: true,
      type: 'select',
      options: [
        'Décès du collaborateur',
        'Changement de contrat vers CDI',
        'Rupture conventionnelle',
        "Licenciement suite à fermeture définitive de l'établissement",
        "Licenciement pour inaptitude physique d'origine non professionnelle",
        "Licenciement pour inaptitude physique d'origine professionnelle",
        "Rupture anticipée à l'initiative du salarié",
        "Rupture anticipée à l'initiative de l'employeur",
        "Rupture anticipée d'un contrat de travail suite à fermeture de l'établissement",
        "Rupture anticipée d'un CDD en cas d'inaptitude physique",
        'Rupture anticipée du contrat de travail pour faute lourde',
        'Rupture anticipée du contrat de travail pour faute grave',
        'Licenciement pour motif économique',
        "Fin de période d'essai à l'initiative du salarié",
        "Fin de période d'essai à l'initiative de l'employeur",
        'Force Majeure',
        'Licenciement pour faute lourde',
        'Mutation au sein du même groupe sans rupture du contrat',
        'Résiliation judiciaire du contrat de travail',
        "Changement d'établissement",
        "Rupture anticipée d'un commun accord",
        'Licenciement pour insuffisance professionnelle',
        'Licenciement pour insuffisance professionnelle',
        'Démission',
        'Mise à la retraite',
        'Licenciement pour faute grave',
        'Licenciement pour faute simple',
        'Fin de contrat',
        'Départ Volontaire à la retraite',
      ],
    },
  },
  {
    header: 'Intitulé du poste',
    required: true,
  },
  {
    header: 'Niveau',
    required: true,
  },
  {
    header: 'Échelon',
    required: true,
  },
  {
    header: 'Salaire brut mensuel',
    required: true,
    cell: {
      type: 'float',
    },
  },
  {
    header: 'Nom du titulaire du compte',
    cell: {
      type: 'string',
    },
  },
  {
    header: 'IBAN',
    required: true,
    cell: {
      type: 'string',
    },
  },
  {
    header: 'Forfait jour. OUI/NON',
    required: true,
    cell: {
      type: 'select',
      defaultValue: 'non',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Type de document (travailleurs étrangers)',
    required: true,
    cell: {
      trim: true,
      strict: true,
      type: 'select',
      options: [
        'Carte de séjour : Compétences et talents',
        'Carte de résident longue durée-UE',
        'Carte de séjour temporaire : Vie privée et familiale',
        'Autorisation provisoire de séjour',
        'VLS-TS ou Carte de séjour "salarié"',
        'VLS-TS ou Carte de séjour "travailleur temporaire"',
        'Carte de séjour "travailleur saisonnier"',
        'Carte de séjour "salarié détaché ICT"',
        'VLS-TS ou Carte de séjour "étudiant"',
        'Carte de séjour "Etudiant Algérien"',
        'Carte de séjour "recherche d\'emploi ou création d\'entreprise"',
        'Récépissé portant la mention "autorise son titulaire à travailler"',
        'Visa vacances-travail',
        'Visa de court séjour Schengen',
        'Salarié détaché travaillant pour un prestataire de services européen',
        'Autorisation provisoire de séjour pour jeunes diplômés',
        'NIE',
        'Pasaporte',
        'Permiso de trabajo',
      ],
    },
  },
  {
    header: 'numéro de document',
    required: true,
  },
  {
    header: 'Date de début du document',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: "Date d'expiration du document",
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: "Date d'autorisation de travail",
    required: false,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Date de dépôt de la demande d’autorisation de travail',
    required: false,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Mutuelle Oui/non',
    required: true,
    cell: {
      type: 'select',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Date dernière visite médicale',
    required: true,
    cell: {
      type: 'date',
    },
  },
  {
    header: 'Visite médicale renforcée. Oui/ non',
    required: true,
    cell: {
      type: 'select',
      options: ['oui', 'non'],
    },
  },
  {
    header: 'Rôle dans Combo',
    required: true,
    cell: {
      trim: true,
      type: 'select',
      defaultValue: 'Employé',
      options: [
        'employée',
        'employé',
        'employee',
        'manager',
        'directeur',
        'director',
        'admin',
        'owner',
      ],
    },
  },
  {
    header: 'Langue',
    required: true,
    cell: {
      defaultValue: 'FR',
      hasFirstOption: false,
      required: true,
      strict: true,
      type: 'select',
      options: ['FR', 'EN', 'ES', 'IT'],
    },
  },
  { header: "Contact d'urgence Prénom" },
  { header: "Contact d'urgence Nom" },
  { header: "Contact d'urgence Relation / Collaborateur" },
  { header: "Contact d'urgence Téléphone" },
  {
    header: 'Personne en situation de handicap',
    cell: {
      type: 'select',
      options: ['oui', 'non'],
      defaultValue: 'non',
    },
  },
  {
    header: 'Type de handicap',
    cell: {
      strict: true,
      type: 'select',
      options: [
        'Travailleur reconnu handicapé par la commission des droits et de l autonomie des personnes handicapées',
        'Victime d accident du travail ou de maladie professionnelle ayant entraîné une incapacité permanente au moins égale à 10 % et titulaire dune rente',
        'Titulaire dune pension d invalidité à condition que l invalidité réduise au moins des deux tiers sa capacité de travail',
        'Bénéficiaire mentionné à l article L.241-2 du Code des pensions militaires d invalidité et des victimes de la guerre',
        'Bénéficiaire mentionné aux articles L.241-3 et L.241-4 du Code des pensions militaires d invalidité et des victimes de la guerre',
        'Titulaire dune allocation ou dune rente d invalidité dans les conditions définies par la Loi n°91-1389 du 31 décembre 1991',
        'Titulaire de la carte mobilité inclusion portant la mention invalidité (L. 241-3 du Code de laction sociale et des familles)',
        'Titulaire de l allocation aux adultes handicapés',
        'Bénéficiaire mentionné aux articles L.241-5 et L.241-6 du Code des pensions militaires d invalidité et des victimes de guerre',
        'Agent public reclassé (3ème alinéa de larticle L.323-5 du Code du travail)',
        'Agent public bénéficiaire dune allocation temporaire d invalidité (4ème alinéa de larticle L.323-5 du Code du travail)',
        'Ayant droit à la prestation de compensation du handicap, à l allocation compensatrice pour tierce personne ou à l allocation déducation de l enfant handicapé bénéficiant d un stage (L.5212-7 du Code du travail)',
      ],
    },
  },
  {
    header: 'Nombre de personnes à charge',
    cell: {
      type: 'number',
    },
  },
]

export default configuration
